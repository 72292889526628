import { useState } from "react";
import Footer from "../../components/shared/footer";
import Navbar from "../../components/shared/navbar";
import axios from "axios";
import * as Config from "../../lib/config";

const SignupPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await axios.post(`${Config.getWebsiteServiceBaseUrl()}/subscribe`, {
        firstName,
        lastName,
        email,
      });
      console.log("Submitted successfully");
    } catch (error) {
      console.error(error);
    } finally {
      // For current state, we are not handling the error case. TODO.
      setIsSubmitted(true);
      setFirstName("");
      setLastName("");
      setEmail("");
    }
  };

  return (
    <div className="signup-page">
      <Navbar />
      <div className="hero">
        <div className="content">
          <div className="hero__content">
            <h1 className="hero__content__heading">
              Get in touch to
              <br />
              <span className="text-span-accent green">Stay Connected</span>
            </h1>
            <p className="hero__content__paragraph">
              Want to be among the first to know what we're up to? Join our
              newsletter to receive updates on our progress, exclusive early
              access, and more. No spam—just the good stuff.
            </p>
          </div>
        </div>
      </div>
      <div className="form-section">
        <div className="content">
          <div className="form-section__form-wrapper">
            {isSubmitted ? (
              <div
                className="form-section__success-message-wrapper"
                role="region"
                aria-label="Email Form success"
              >
                <div className="form-section__success-message-text">
                  Thank you, your submission has been received!
                </div>
              </div>
            ) : (
              <form className="form-section__form" onSubmit={handleSubmit}>
                <div className="form-section__field-group">
                  <div className="form-section__field">
                    <label
                      htmlFor="first-name"
                      className="form-section__field-label"
                    >
                      First name <span>(required)</span>
                    </label>
                    <input
                      maxLength={256}
                      name="First-Name"
                      data-name="First Name"
                      placeholder="First Name"
                      type="text"
                      id="first-name"
                      required
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="form-section__field">
                    <label
                      htmlFor="last-name"
                      className="form-section__field-label"
                    >
                      Last Name
                    </label>
                    <input
                      className="text-field"
                      maxLength={256}
                      name="Last-Name"
                      data-name="Last Name"
                      placeholder="Last Name"
                      type="text"
                      id="last-name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-section__field">
                  <label htmlFor="email" className="form-section__field-label">
                    Email <span>(required)</span>
                  </label>
                  <input
                    className="text-field"
                    maxLength={256}
                    name="email"
                    data-name="Email"
                    placeholder="Your Email"
                    type="email"
                    id="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <input
                  type="submit"
                  data-wait="Please wait..."
                  className="submit-button"
                  value="Submit"
                  disabled={!firstName || !email}
                ></input>
              </form>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignupPage;
