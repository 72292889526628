import { useEffect, useRef } from "react";
import LogoSvg from "../../../assets/svg/logo.svg";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const navbarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Navbar scroll animation
    const navbarElement = navbarRef.current;
    if (navbarElement) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 50) {
          navbarElement.classList.add("scrolled");
        } else {
          navbarElement.classList.remove("scrolled");
        }
      });
    }
  }, []);

  return (
    <div className="navbar" ref={navbarRef} onClick={() => navigate("/")}>
      <img src={LogoSvg} alt="Logo" className="navbar__logo" />
    </div>
  );
};

export default Navbar;
