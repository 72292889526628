import Footer from "../../components/shared/footer";
import HeroSection from "../../components/landing-page/hero";
import MainSection from "../../components/landing-page/main-section";
import Navbar from "../../components/shared/navbar";

const LandingPage = () => {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <MainSection />
      <Footer />
    </div>
  );
};

export default LandingPage;
