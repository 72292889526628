import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ValuePropImage2 from "../../../assets/images/value-prop-image-2.png";
import InnovationSvg from "../../../assets/svg/innovation.svg";
import ClaimControlSvg from "../../../assets/svg/claim-control.svg";

gsap.registerPlugin(ScrollTrigger);

const MainSection = () => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Image scroll animation
    gsap.fromTo(
      imageRef.current,
      { y: 100, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.5,
        ease: "power4.out",
        scrollTrigger: {
          trigger: imageRef.current,
          start: "top 90%",
          end: "bottom 60%",
          scrub: true,
        },
      }
    );

    const imageElement = imageRef.current;
    if (imageElement) {
      gsap.set(imageElement, { transformOrigin: "center" });
      gsap.to(imageElement, {
        duration: 3,
        rotationY: 30,
        rotationX: 5,
        yoyo: true,
        repeat: -1,
        ease: "power1.inOut",
      });
    }

    // Add fade & slide-in animation for content section
    gsap.fromTo(
      contentRef.current,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.5,
        ease: "power4.out",
        scrollTrigger: {
          trigger: contentRef.current,
          start: "top 80%",
          end: "bottom 50%",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div className="main-section">
      <div className="content">
        <div className="main-section__grid">
          <div className="main-section__grid__image">
            <img ref={imageRef} src={ValuePropImage2} alt="AI Innovation" />
          </div>
          <div className="main-section__grid__content" ref={contentRef}>
            <h2 className="main-section__grid__content__header">
              Built for simplicity.
              <br />
              <span className="text-span-accent green">Designed for You.</span>
            </h2>
            <p className="main-section__grid__content__paragraph">
              We're committed to ensuring you receive what's rightfully yours.
              By dismantling outdated systems and reimagining the claims
              process, we're putting the power back in your hands and making it
              simpler than ever. Be part of this change—join us now.
            </p>
            <div className="main-section__grid__content__bullet">
              <h6 className="main-section__grid__content__bullet__header">
                <span>
                  <img src={InnovationSvg} alt="Innovation" />
                </span>
                Empowered by Innovation
              </h6>
              <p className="main-section__grid__content__bullet__paragraph">
                We're not just simplifying the claims process—we're reinventing
                it. Our unique approach ensures everyone gets their rightful
                share, without the usual hassle or complexity.
              </p>
            </div>

            <div className="main-section__grid__content__bullet">
              <h6 className="main-section__grid__content__bullet__header">
                <span>
                  <img src={ClaimControlSvg} alt="Money" />
                </span>
                Your Claim, Your Control
              </h6>
              <p className="main-section__grid__content__bullet__paragraph">
                Stay informed every step of the way. With our transparent
                process, you'll always know what's happening, giving you full
                control over your claim.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
