import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as THREE from "three";
import ValuePropImage1 from "../../../assets/images/value-prop-image-1.png";

gsap.registerPlugin(ScrollTrigger);

const HeroSection = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const geoSceneRef = useRef<HTMLDivElement | null>(null);
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Set up Three.js scene
    const canvas = canvasRef.current;
    if (!canvas) return;

    const width = geoSceneRef.current?.clientWidth || window.innerWidth;
    const height = geoSceneRef.current?.clientHeight || window.innerHeight;
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(80, width / height, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ canvas, alpha: true });
    renderer.setSize(width, height);

    // Create a 3D object (e.g., a rotating torus)
    const geometry = new THREE.TorusKnotGeometry(10, 3, 100, 16);
    const material = new THREE.MeshBasicMaterial({
      color: 0x00a2ff,
      wireframe: true,
    });
    const torusKnot = new THREE.Mesh(geometry, material);
    scene.add(torusKnot);

    camera.position.z = 50;

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      torusKnot.rotation.x += 0.008;
      torusKnot.rotation.y += 0.008;
      renderer.render(scene, camera);
    };
    animate();

    // GSAP ScrollTrigger for sticky canvas effect
    gsap.to(camera.position, {
      scrollTrigger: {
        trigger: canvas,
        scrub: true,
      },
      z: 28,
      ease: "power1.inOut",
    });

    // Add slide-up animation for value prop card
    gsap.fromTo(
      cardRef.current,
      { y: 300, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 3,
        ease: "power4.out",
        scrollTrigger: {
          trigger: cardRef.current,
          // start: "top 90%",
          // end: "bottom 50%",
          scrub: true,
        },
      }
    );

    // Fade out canvas when card comes into view
    gsap.to(canvas, {
      opacity: 0,
      duration: 1,
      scrollTrigger: {
        trigger: cardRef.current,
        start: "top bottom", // Trigger the fade-out sooner
        end: "bottom center",
        scrub: true,
      },
    });

    // Handle window resizing
    const handleResize = () => {
      const width = geoSceneRef.current?.clientWidth || window.innerWidth;
      const height = geoSceneRef.current?.clientHeight || window.innerHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="hero">
      <div className="content">
        <div className="hero__content">
          <h1 className="hero__content__heading">
            Get Back What You Deserve
            <span className="text-span-accent">Effortlessly</span>
          </h1>
          <p className="hero__content__paragraph">
            Introducing a game-changing solution that will help you claim your
            share of class action settlements. Powered by cutting-edge AI, our
            platform makes the process smarter and more efficient. Sign up now
            to get exclusive early access and be the first to know.
          </p>
          <a href="/signup" className="button dark">
            Stay in the Loop
          </a>
        </div>
      </div>
      <div className="geo-hero">
        <div className="geo-hero__scene" ref={geoSceneRef}>
          <canvas ref={canvasRef} className="geo-hero__canvas"></canvas>
        </div>
      </div>
      <div className="content">
        <div className="value-prop-card" ref={cardRef}>
          <img
            src={ValuePropImage1}
            className="value-prop-card__image"
            alt="hero graphic"
          />
          <div className="value-prop-card__content">
            <div className="value-prop-card__section">
              <h6 className="value-prop-card__section__header">
                Get Paid What You're Owed
              </h6>
              <p className="value-prop-card__section__paragraph">
                We simplify the process of claiming class action settlements,
                ensuring you get what you deserve without the hassle.
              </p>
            </div>

            <div className="value-prop-card__section">
              <h6 className="value-prop-card__section__header">
                Seamless Experience
              </h6>
              <p className="value-prop-card__section__paragraph">
                Our AI-driven technology takes care of the complexities, saving
                you time and effort.
              </p>
            </div>

            <div className="value-prop-card__section">
              <h6 className="value-prop-card__section__header">
                More Money Back to You
              </h6>
              <p className="value-prop-card__section__paragraph">
                With only 4% of settlements currently claimed, we're here to
                change that and put more money back into people's pockets.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
